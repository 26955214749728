import React from "react";
import { useParams } from "react-router-dom";

import Container from "@material-ui/core/Container";
import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Skeleton from "@material-ui/lab/Skeleton";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import * as api from "../api/vote";

import "./Payment.css";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
  },
  media: {
    height: 0,
    paddingTop: "96.25%", // 16:9
  },
  avatar: {
    backgroundColor: red[500],
  },
  section1: {
    margin: theme.spacing(1, 2),
  },
  section2: {
    margin: theme.spacing(2),
  },
  section3: {
    margin: theme.spacing(3, 1, 1),
  },
  voteNumber: {
    marginTop: theme.spacing(1),
  },
  chip: {
    margin: theme.spacing(0.5),
    cursor: "pointer",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const votePlans = [
  { id: 1, vote_number: "10", vote_price: "10.00" },
  { id: 2, vote_number: "20", vote_price: "20.00" },
  { id: 3, vote_number: "50", vote_price: "45.00" },
  { id: 4, vote_number: "100", vote_price: "90.00" },
  { id: 5, vote_number: "500", vote_price: "450.00" },
  { id: 6, vote_number: "1000", vote_price: "850.00" },
  { id: 7, vote_number: "1500", vote_price: "1200.00" },
  { id: 8, vote_number: "2500", vote_price: "1750.00" },
  { id: 9, vote_number: "3000", vote_price: "1800.00" },
  { id: 10, vote_number: "5000", vote_price: "2500.00" },
];

const Payment = () => {
  let { contestant_id } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [selectedChip, setSelectedChip] = React.useState(votePlans[0]);
  const [contestant, setContestant] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);

  const handleVotePlan = (id) => {
    const selectChip = votePlans.find((vote_plan) => vote_plan.id === id);
    setSelectedChip(selectChip);
  };

  const renderedChips = votePlans.map((vote_plan) => {
    return (
      <Chip
        key={vote_plan.id}
        className={classes.chip}
        label={`${vote_plan.vote_number} votes`}
        color={selectedChip.id === vote_plan.id ? "primary" : "default"}
        onClick={() => handleVotePlan(vote_plan.id)}
      />
    );
  });

  const handleVote = () => {
    setOpen(!open);
    const formValues = {
      vote_number: selectedChip.vote_number,
      vote_price: selectedChip.vote_price,
      vote_type: "save_artist",
      cont_id: contestant.id,
    };
    api
      .payNow(formValues)
      .then((response) => {
        setOpen(false);
        window.open(response.checkoutDirectUrl, "_self");
      })
      .catch((err) => {
        const error = err.response.data;
        console.error(error);
      });
  };

  React.useEffect(() => {
    api
      .getContestant(contestant_id)
      .then((response) => {
        if (response.status === false) {
          return history.push("/page/404");
        }
        setLoading(false);
        setContestant(response.data.contestant);
      })
      .catch((err) => {
        const error = err.response;
        setLoading(false);
        console.error(error);
      });
  }, [contestant_id, history]);

  React.useEffect(() => {
    // When the user scrolls the page, execute myFunction
    window.onscroll = () => {
      var header = document.getElementById("header");

      // Get the offset position of the navbar
      var sticky = header.offsetTop;

      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    };
  }, []);
  return (
    <>
      <Container
        container="true"
        id="header"
        style={{
          backgroundColor: "transparent",
          backgroundImage:
            "linear-gradient(0deg,rgba(255,255,255,0.53),#ffffff)",
        }}
      >
        <div
          className="contain"
          style={{ display: "grid", gridTemplateColumns: "1fr" }}
        >
          <div
            className="item"
            style={{ marginTop: "29px", marginBottom: "29px" }}
          >
            <div className="logo">
              <a href="https://realityshows.3news.com" title="Reality shows">
                <img
                  src="https://gmb2020.s3.us-east-2.amazonaws.com/reality-shows-portal-logo.png"
                  alt="Reality Shows Portal"
                  style={{ width: "140px" }}
                />
              </a>
            </div>
          </div>
        </div>
      </Container>
      <Container
        container="true"
        spacing={3}
        maxWidth="sm"
        style={{ marginBottom: "100px" }}
      >
        <Card className={classes.root}>
          <CardHeader
            avatar={
              loading ? (
                <Skeleton
                  animation="wave"
                  variant="circle"
                  width={40}
                  height={40}
                />
              ) : (
                <Avatar aria-label="recipe" className={classes.avatar}>
                  {contestant?.first_name?.charAt(0)}
                </Avatar>
              )
            }
            action={
              loading ? null : (
                <Typography
                  gutterBottom
                  variant="h6"
                  className={classes.voteNumber}
                >
                  {`₵${selectedChip.vote_price}`}
                </Typography>
              )
            }
            title={
              loading ? (
                <Skeleton
                  animation="wave"
                  height={10}
                  width="80%"
                  style={{ marginBottom: 6 }}
                />
              ) : (
                `${contestant?.first_name} ${contestant?.last_name} (${contestant?.stage})`
              )
            }
            subheader={
              loading ? (
                <Skeleton animation="wave" height={10} width="40%" />
              ) : (
                contestant?.town
              )
            }
          />
          {loading ? (
            <Skeleton
              animation="wave"
              variant="rect"
              className={classes.media}
            />
          ) : (
            <CardMedia
              className={classes.media}
              image={contestant?.cover_pic}
              title="Paella dish"
            />
          )}

          <CardContent>
            {loading ? (
              <Skeleton
                animation="wave"
                height={15}
                style={{ marginBottom: 10 }}
              />
            ) : (
              <Typography
                variant="h6"
                color="textSecondary"
                className={classes.section1}
              >
                {`Vote for ${contestant?.first_name} ${contestant?.last_name} (${contestant?.stage})`}
              </Typography>
            )}
            <Divider variant="middle" />
            <div className={classes.section2}>
              {loading ? (
                <React.Fragment>
                  <Skeleton
                    animation="wave"
                    height={10}
                    style={{ marginBottom: 6 }}
                  />
                  <Skeleton animation="wave" height={10} width="80%" />
                </React.Fragment>
              ) : (
                <>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                  >
                    Select Vote Plan
                  </Typography>
                  <div>{renderedChips}</div>
                  <div className={classes.section3}>
                    <Button color="primary" onClick={handleVote} disabled={contestant?.status !== 'active'}>
                      Vote Now
                    </Button>
                  </div>
                </>
              )}
            </div>
          </CardContent>
        </Card>
      </Container>
      <Container container="true">
        <div className="copyright__logo">
          <a href="https://realityshows.3news.com" title="Reality shows">
            <img
              src="https://gmb2020.s3.us-east-2.amazonaws.com/reality-shows-portal-logo.png"
              alt="Reality Shows Portal"
              style={{
                width: "100px",
                marginTop: "30px",
                marginBottom: "20px",
              }}
            />
          </a>
        </div>
        <div className="copyright">
          <div className="copyright__item1">
            <p className="copyright__text">
              Copyright © 2022 Reality Shows Portal. All Rights Reserved
            </p>
          </div>
          <div className="copyright__item2">
            <p className="copyright__text">Powered by: MG Digital</p>
          </div>
        </div>
      </Container>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Payment;
